<template>
  <tif
    v-if="src && src.split('.').pop() === 'tiff'"
    :src="src"
    :imgClass="imgClass"
    :width="width"
  />
  <img v-else-if="src" :src="src" :class="imgClass" :width="width" />
  <img v-else src="@/assets/no-image.png" :class="imgClass" :width="width" />
</template>

<script>
import Tif from "@/components/utils/Tif.vue";

export default {
  name: "custom-image",
  components: {
    Tif,
  },
  props: {
    src: {
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    imgClass: {
      required: false,
    },
  },
};
</script>
