<template>
  <div>
    <img
      :src="imgData"
      style="max-width: none"
      :class="imgClass"
      :width="width"
    />
    <canvas ref="canvas" class="hidden"></canvas>
  </div>
</template>

<script>
import UTIF from "utif";

export default {
  name: "tif",
  mounted() {
    fetch(this.src)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.arrayBuffer();
      })
      .then((data) => {
        const ifds = UTIF.decode(data);
        UTIF.decodeImage(data, ifds[0]);
        const rgba = UTIF.toRGBA8(ifds[0]);
        const width = ifds[0].width;
        const height = ifds[0].height;

        const canvas = this.$refs.canvas;
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        const imgData = new ImageData(
          new Uint8ClampedArray(rgba.buffer),
          width,
          height
        );
        if (ctx) {
          ctx.putImageData(imgData, 0, 0);
          canvas.toBlob((blob) => {
            this.imgData = URL.createObjectURL(blob);
          });
        }
      });
  },
  props: {
    src: {
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    imgClass: {
      required: false,
    },
  },
  data() {
    return {
      imgData: "",
    };
  },
};
</script>
